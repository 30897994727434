import React, { useState, useRef, useLayoutEffect, useContext } from 'react'
import "./Write.css"
import Editor from "react-medium-editor";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/beagle.css";
import { topics } from '../home/topics';
import { UserContext } from '../../utils/userContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Cookies from 'js-cookie'
import toast from 'react-hot-toast';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Write = () => {
    const navigate = useNavigate();
    const [user] = useContext(UserContext);
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currTopic, setCurrTopic] = useState("all");
    const input = useRef(null);

    useEffect(() => {
        !user && navigate('/app');
    }, [user, navigate]);

    useLayoutEffect(() => {
        input.current.focus();
    }, [])

    const handlePublish = async () => {
        if (!title) {
            toast('Please enter a title!', {
                icon: '✍🏻',
            });
            setIsModalOpen(false);
            return;
        }
        if (!content) {
            toast('Please write something!', {
                icon: '✍🏻',
            });
            setIsModalOpen(false);
            return;
        }
        Axios.post(process.env.REACT_APP_SERVER_URL + "/api/stories/publish", {
            title,
            brief: content.replace(/<[^>]+>/g, '').slice(0, 80)+"...",
            content,
            tags:currTopic
        }, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token")
            }
        }).then((res) => {
            console.log(res.data);
            toast.success('Story published successfully');
            // alert("Post published successfully");
            setTimeout(() => {
                toast.remove();
                navigate('/app');
            }, 2000);

        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data)
        })
    }

    return (
        <div className='Write'>
            <section className="title">
                <input ref={input} type="text" name="" id="" placeholder='Title' onChange={(e) => setTitle(e.target.value)} />
            </section>
            <section className="content">
                <Editor
                    tag="div"
                    text={content}
                    onChange={(text) => setContent(text)}
                    options={{
                        // extensions: {
                        //   embedButton: new EmbedButtonExtension(),
                        // },
                        toolbar: {
                            buttons: [
                                "bold",
                                "italic",
                                "underline",
                                "anchor",
                                "h1",
                                "h2",
                                "h3",
                                "h4",
                                "h5",
                                "h6",
                                "quote",
                                "justified",
                                "unorderedlist",
                                "orderedlist",
                                "subscript",
                                "superscript",
                                "outdent",
                                "indent",
                                "code",
                                "horizontal",
                            ],
                        },
                        placeholder: {
                            text: "Write  your story",
                        },

                        autoLink: true,
                        anchor: {
                            placeholderText: "Enter reference link",
                            // customClassOption: "btn",
                            // customClassOptionText: "Refernce link",
                        },
                        paste: {
                            cleanPastedHTML: true,
                            cleanAttrs: ["style", "dir"],
                            cleanTags: ["label", "meta"],
                        },
                        anchorPreview: {
                            hideDelay: 300,
                        },
                    }}
                />
                <button className='publish' onClick={() => { setIsModalOpen(true) }}>
                    Publish
                </button>
                <Modal className="topics-con" id='topics-con' isOpen={isModalOpen} style={customStyles} onRequestClose={() => setIsModalOpen(false)}>
                    <p className='topic-header'>Select a topic : </p>
                    <div className="topics">
                        {topics.length && topics.map((topic) => {
                            return (
                                <div className="topic" key={topic.id} onClick={() => setCurrTopic(topic.value)} id={currTopic === topic.value ? "highlight" : ""}>
                                    <p>{topic.topic}</p>
                                </div>
                            )
                        })}
                    </div>
                    <button onClick={() => handlePublish()}>Publish</button>
                </Modal>
            </section>

        </div>
    )
}

export default Write