import React, { useEffect, useState, useRef, useContext } from 'react'
import './Story.css'
import { Skeleton,Avatar } from 'antd';
import Follow from '../../components/follow/Follow'
import { useParams } from 'react-router-dom';
import heart from '../../assets/icons/heart.svg'
import outlined_heart from '../../assets/icons/heart-outlined.svg'
import chain from '../../assets/icons/chain.svg'
import play from '../../assets/icons/play.svg'
import pause from '../../assets/icons/pause.svg'
import appLogo from '../../assets/icons/writr.svg'
import Axios from 'axios';
import parse from 'html-react-parser';
import toast from 'react-hot-toast';
import { useSpeechSynthesis } from 'react-speech-kit';
import { ProfileContext } from '../../utils/profileContext';
import Cookies from 'js-cookie';
import { UserContext } from '../../utils/userContext';
import advert_snake2 from '../../assets/images/advertsnake2.png'
import advert_snake1 from '../../assets/images/advertsnake1.png'
import { useNavigate } from 'react-router-dom';
import connect from '../../assets/images/connect.svg'
import mint from '../../assets/images/mint.png'
import mint_confirm from '../../assets/images/mint-confirmed.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

const Story = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [story, setStory] = useState(null);
  // const [speech, setSpeech] = useState("");
  const storyString = useRef(null);
  const [profile] = useContext(ProfileContext);
  const [user] = useContext(UserContext);
  const [likes, setLikes] = useState([]);
  const [isMinting, setIsMinting] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Axios.get(process.env.REACT_APP_SERVER_URL + "/api/stories/" + id, {}).then((res) => {
      setStory(res.data);
      setLikes(res.data.likes);
      setIsLoading(false);
      console.log(res.data);
      // setSpeech(storyString.current.textContent);
    }).catch((err) => {
      setIsLoading(false);
      console.log(err);
    })
  }, []);

  const { speak, cancel, voices } = useSpeechSynthesis();

  const handleSpeech = (speech) => {
    setIsSpeaking(true);
    speak({ text: speech, voice: voices[6] });
  }

  const handleLike = (id) => {
    if (!user.issuer) {
      toast.error("Please login to like this story");
      return;
    }
    Axios.patch(process.env.REACT_APP_SERVER_URL + `/api/stories/like/${id}`, {}, {
      headers: {
        "Authorization": "Bearer " + Cookies.get("token")
      }
    }).then((res) => {
      if (res.data === "Story liked successfully") {
        setLikes([...likes, profile._id]);
      } else {
        setLikes(likes.filter((like) => like !== profile._id));
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  const handleFollow = (id) => {
    if (story?.author === profile?._id) {
      toast.error("You can't follow yourself");
      return;
    }
    if (!user.issuer) {
      toast.error("Please login to follow this user");
      return;
    }
    const didToken = Cookies.get("token");
    Axios.post(process.env.REACT_APP_SERVER_URL + "/api/user/follow", {
      id: id
    }, {
      headers: {
        Authorization: "Bearer " + didToken
      }
    }).then((res) => {
      console.log(res.data);
      toast.success("Followed the author!");
    }).catch((err) => {
      console.log(err);
      toast.error(err.response.data);
    });
  }

  return (
    <div className='Story'>
      <div className="left-con">
        <section className="writer-info">
          <div className='writer-info-left'>
            <Avatar size={55} style={{ color: '#7E47FF', backgroundColor: '#DCCDFF', cursor: 'pointer' }}>A</Avatar>
            <div className='writer-info-mid'>
              <p>{story?.userName}</p>
              <div className="lower">
                <p>{moment(story?.createdAt).format('Do MMM YY')}</p>
                <div className="action-con">
                  <CopyToClipboard text={window.location.href}>
                      <img src={chain} alt="Link" />
                  </CopyToClipboard>
                  {isSpeaking ? <img src={pause} alt="Pause" onClick={() => { cancel(); setIsSpeaking(false); }} /> :
                    <img src={play} alt="PLay" onClick={() => handleSpeech(storyString.current.textContent)} />}
                  <p> Listen</p>
                  {/* <p onClick={() => cancel()}> cancel</p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="writer-info-right">
            <div className='likes'>
              {likes?.includes(profile?._id) ? <img src={heart} alt="heart" onClick={() => handleLike(story?._id)} /> : <img src={outlined_heart} alt="heart" onClick={() => handleLike(story?._id)} />}
              <p>{likes?.length}</p>
            </div>
            <button onClick={() => handleFollow(profile?._id)}>
              Follow
            </button>
          </div>
        </section>

        <section className="story-content">
          <br />
          {isLoading && (
                      <Skeleton
                        active
                        loading={isLoading}
                        paragraph={{
                          rows: 6,
                        }}
                      />
                    )}
          <h1 className="story-content-title">
            {story?.title}
          </h1>
          <div className="story-content-read" ref={storyString}>
            {story && parse(story?.content)}
          </div>
        </section>
      </div>
      <div className="right-con">
        {user && user.issuer ? story?.author === profile?._id ? isMinting ? isConfirmed ? <img src={mint_confirm} alt="Confirm" /> : <img src={mint} alt="Mint" onClick={() => setIsConfirmed(true)} /> :
          <img src={connect} alt="Connect" onClick={() => setIsMinting(true)} /> :
          <Follow />
          : <div className='advert'>
            <img src={appLogo} alt="Writr" />
            <p>Become a writr today and share your art!</p>
            <button onClick={() => navigate("/register")}>Get Started</button>
            <img src={advert_snake1} alt="zig-zag" className='advert-snake1' />
            <img src={advert_snake2} alt="zig-zag" className='advert-snake2' />
          </div>}
      </div>
    </div>
  )
}

export default Story