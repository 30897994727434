import React, { useState, useContext, useEffect } from 'react'
import './Login.css'
import bubbles from '../../assets/images/bubbles.gif'
import back from '../../assets/icons/back.png'
import appLogo from '../../assets/icons/writr.svg'
import discord from '../../assets/icons/discord.svg'
import github from '../../assets/icons/github.svg'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Axios from 'axios'
import { magic } from '../../utils/magic';
import { UserContext } from '../../utils/userContext'
import Cookies from 'js-cookie'
import loader from '../../assets/images/loader.svg'
import toast from 'react-hot-toast';


const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [user, setUser] = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        user && user.issuer && navigate('/app');
    }, [user, navigate]);

    const handleLogin = async () => {
        setLoading(true);
        if (email === "") {
            toast.error("Please provide your email!");
            setLoading(false);
        } else {
            //check valid email
            if (!email.includes("@")) {
                toast.error("Please enter a valid email address!");
                setLoading(false);
                return;
            }
            try {
                //checking if user exists
                const resp = await Axios.post(process.env.REACT_APP_SERVER_URL + "/api/user/check", {
                    email: email
                });
                if (!resp.data.status) {
                    toast.error("Please register first!");
                    setLoading(false);
                    return navigate("/register");
                }
                // Trigger Magic link to be sent to user
                let didToken = await magic.auth.loginWithMagicLink({
                    email,
                });

                // Validate didToken with server
                try {
                    const loginResp = await Axios.post(process.env.REACT_APP_SERVER_URL + "/api/user/login", { email }, {
                        headers: {
                            Authorization: "Bearer " + didToken
                        }
                    });
                    if (loginResp.status === 200) {
                        let userMetadata = await magic.user.getMetadata();
                        setUser(userMetadata);
                        Cookies.set('token', didToken);
                        navigate("/app");
                    }

                } catch (err) {
                    toast.error("Login attempt failed. Please try again later!");
                    setLoading(false);
                    console.log(err);
                }


            } catch (err) {
                toast.error("Login attempt failed. Please try again later!");
                setLoading(false);
                console.log(err);
            }
        }
    }
    return (
        <motion.div className='Login'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}>
            <nav className='nav'>
                <img src={back} alt="Go back" onClick={() => navigate("/")} />
            </nav>
            <div className="auth-con">
                <img src={bubbles} alt="" className='bubbles'/>
                <div className="auth-card">
                    <img src={appLogo} alt="" />
                    <p className='authcard-head'>Welcome Back</p>
                    <p className='authcard-small'>Let's discover some amazing content</p>
                    <br />
                    <input type="text" placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                    {loading ? <img className="loader" src={loader} alt="Loading..." /> :
                        <>
                            <button onClick={handleLogin}>Log in</button>
                            <div className='no-acc'>
                                <p>No Account?</p>
                                <span onClick={() => navigate("/register")}>Sign up</span>
                            </div>
                        </>
                    }
                </div>
                <img src={bubbles} alt="" className='bubbles' />
            </div>

            <footer className='footer'>
                <div className="brand">
                    <img src={appLogo} alt="Writr" />
                </div>
                <p className="copyright">©Writr 2022</p>
                <div className="socials">
                    <a href="https://github.com/anomic30/Writr">
                        <img src={github} alt="GitHub" />
                    </a>
                    <a href="https://discord.gg/9saMayYZEG">
                        <img src={discord} alt="Discord" />
                    </a>
                </div>
            </footer>
        </motion.div>
    )
}

export default Login