import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import appLogo from '../../assets/icons/writr.svg'
import { UserContext } from '../../utils/userContext'
import { Dropdown, Menu, Space, Avatar } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { magic } from '../../utils/magic';
import { ProfileContext } from '../../utils/profileContext';
import Cookies from 'js-cookie'
import './Navbar.css'

const Navbar = () => {
    const navigate = useNavigate();
    const [user, setUser] = useContext(UserContext);
    const [profile, setProfile] = useContext(ProfileContext);

    const onClick = ({ key }) => {
        if (key == 2) {
            magic.user.logout().then(() => {
                setUser({ user: null });
                setProfile({ profile: null });
                Cookies.remove("token");
                navigate("/");
            });
        }else if(key == 1){
            navigate("/app");
        }
    };

    const menu = (
        <Menu className='menu-box'
            onClick={onClick}
            items={[
                {
                    label: profile?.userName,
                    key: '1',
                    icon: <UserOutlined />
                },
                {
                    label: 'Logout',
                    key: '2',
                    icon: <LogoutOutlined style={{ color: '#ff5c5c' }} />,
                }
            ]}
        />
    );
    return (
        <nav className='navbar'>
            <div>
                <img src={appLogo} alt="Writr" onClick={() => navigate("/app")} />
            </div>
            {user && user.issuer ?
                <div className="loggedin-con">
                    {window.location.pathname === '/app/write' ?
                        null
                        : <button onClick={() => navigate("/app/write")}>
                            Start Writing
                        </button>}
                    <Dropdown overlay={menu} placement="bottomRight" >
                        <Space>
                            <Avatar size={40} style={{ color: '#7E47FF', backgroundColor: '#DCCDFF', cursor: 'pointer' }}>{profile?.userName?.slice(0, 1)}</Avatar>
                        </Space>
                    </Dropdown>
                </div> :
                <div className='login-con'>
                    <button id='login-btn' onClick={() => navigate("/login")}>Login</button>
                    <button onClick={() => navigate("/register")}>Signup</button>
                </div>
            }
        </nav>
    )
}

export default Navbar