import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from '../pages/login/Login';
import Register from '../pages/register/Register';
import Landing from '../pages/landing/Landing';
import Main from '../pages/main/Main';
import Home from '../pages/home/Home';
import { AnimatePresence } from 'framer-motion'
import Write from '../pages/write/Write';
import Story from './story/Story';

function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence exitBeforeEnter>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Landing />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/app" element={<Main />}>
                    <Route path="/app" element={<Home />} />
                    <Route path="/app/write" element={<Write />} />
                    <Route path="/app/story/:id" element={<Story />} />
                </Route>
                </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes