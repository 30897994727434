export const faqs = [
    {
        question: "What is Writr?",
        answer: "Writr is a platform for writers to share their stories with the world. It is a place where you can find stories that you can relate to, and also share your own stories with others."
    },
    {
        question: "Is it free?",
        answer: "Writr is completely free to use as of now. However in future we may add certain features which will be paid."
    },
    {
        question: "How do I write a story?",
        answer: "You can write a story by clicking on the 'Start Writing' button on the top right corner of the screen."
    },
    {
        question: "How do I share my story?",
        answer: "You can share your story by clicking on the 'Share' icon in the stories page."
    },
    {
        question: "Is it safe to share my story?",
        answer: "Yes, it is completely safe to share your story. We do not share your personal information with anyone."
    },
    {
        question: "Can I mint NFTs from my stories?",
        answer: "Yes, you can mint NFTs from your stories. You can do so by clicking on the 'Mint NFT' button in the stories page."
    },
]