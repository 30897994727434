import React, { useState, useContext } from 'react'
import './Register.css'
import bubbles from '../../assets/images/bubbles.gif'
import back from '../../assets/icons/back.png'
import appLogo from '../../assets/icons/writr.svg'
import discord from '../../assets/icons/discord.svg'
import github from '../../assets/icons/github.svg'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Axios from 'axios'
import { UserContext } from '../../utils/userContext'
import loader from '../../assets/images/loader.svg'
import toast from 'react-hot-toast';


const Register = () => {
    const navigate = useNavigate();
    const [user, setUser] = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [loading, setLoading] = useState(false);

    const handleRegistration = async () => {
        setLoading(true);
        if (email === "" || userName === "") {
            toast.error("Please fill all the fields");
            setLoading(false);
        } else {
            //check valid email
            if (!email.includes("@")) {
                toast.error("Please enter a valid email");
                setLoading(false);
                return;
            }
            try {
                //checking if user exists
                const check = await Axios.post(process.env.REACT_APP_SERVER_URL + "/api/user/check", {
                    email: email
                });
                if (check.data.status) {
                    setLoading(false);
                    return toast.error("User already exists");
                }
                const resp = await Axios.post(process.env.REACT_APP_SERVER_URL + "/api/user/register", {
                    email: email,
                    userName: userName
                });
                if (resp.status === 201) {
                    toast.success("Registration Successful");
                    setLoading(false);
                    navigate("/login");
                }
            } catch (err) {
                toast.error("Something went wrong");
                setLoading(false);
                console.log(err);
            }
        }
    }

    return (
        <motion.div className='Register'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}>
            <nav className='nav'>
                <img src={back} alt="Go back" onClick={() => navigate("/")} />
            </nav>
            <div className="auth-con">
                <img src={bubbles} alt="" className='bubbles'/>
                <div className="auth-card">
                    <img src={appLogo} alt="" />
                    <p className='authcard-head'>Sign up for Free</p>
                    <p className='authcard-small'>Let's discover some amazing content</p>
                    <br />
                    <input type="text" placeholder='Name' onChange={(e) => setUserName(e.target.value)} />
                    <input type="text" placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                    {loading ? <img className="loader" src={loader} alt="Loading..." /> :
                        <>
                            <button onClick={handleRegistration}>Get Started</button>
                            <div className='no-acc'>
                                <p>Have and account?</p>
                                <span onClick={() => navigate("/login")}>Log in</span>
                            </div>
                        </>}
                </div>
                <img src={bubbles} alt="" className='bubbles'/>
            </div>

            <footer className='footer'>
                <div className="brand">
                    <img src={appLogo} alt="Writr" />
                </div>
                <p className="copyright">©Writr 2022</p>
                <div className="socials">
                    <a href="https://github.com/anomic30/Writr">
                        <img src={github} alt="GitHub" />
                    </a>
                    <a href="https://discord.gg/9saMayYZEG">
                        <img src={discord} alt="Discord" />
                    </a>
                </div>
            </footer>
        </motion.div>
    )
}

export default Register