import React, { useContext, useEffect, useLayoutEffect } from 'react'
import './Main.css'
import { Outlet, useNavigate } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar'
import { UserContext } from '../../utils/userContext'
import { ProfileContext } from '../../utils/profileContext'
import Cookies from 'js-cookie'
import Axios from 'axios'
import { magic } from '../../utils/magic';

function Main() {
    const navigate = useNavigate();
    const [user] = useContext(UserContext);
    const [profile, setProfile] = useContext(ProfileContext);

    useLayoutEffect(() => {
        const didToken = Cookies.get("token");
        if (magic.user.isLoggedIn()) {
            Axios.get(process.env.REACT_APP_SERVER_URL + "/api/user/profile", {
                headers: {
                    Authorization: "Bearer " + didToken
                }
            }).then((res) => {
                console.log(res.data);
                setProfile(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [user, setProfile]);

    return (
        <div className='Main'>
            <Navbar />
            <Outlet />
        </div>
    )
}

export default Main