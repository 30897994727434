export const topics = [
    {
        id: 1,
        topic: "Games",
        value: "games",
    },
    {
        id: 2,
        topic: "Movies",
        value: "movies",
    },
    {
        id: 3,
        topic: "Music",
        value: "music",
    },
    {
        id: 4,
        topic: "Books",
        value: "books",
    },
    {
        id: 5,
        topic: "Sports",
        value: "sports",
    },
    {
        id: 6,
        topic: "Technology",
        value: "technology",
    },
    {
        id: 7,
        topic: "Politics",
        value: "politics",
    },
    {
        id: 8,
        topic: "Science",
        value: "science",
    },
    {
        id: 9,
        topic: "Art",
        value: "art",
    },
    {
        id: 10,
        topic: "Food",
        value: "food",
    },
    {
        id: 11,
        topic: "All",
        value: "all",
    }
]