import React, { useState, useContext, useLayoutEffect, useEffect } from 'react'
import { Skeleton, Tabs } from 'antd';
import './Home.css'
import Card from '../../components/card/Card'
import Follow from '../../components/follow/Follow'
import { topics } from './topics';
import searchIcon from '../../assets/icons/search.svg'
import { motion } from 'framer-motion'
import { UserContext } from '../../utils/userContext';
import Cookies from 'js-cookie'
import Axios from 'axios'
import { magic } from '../../utils/magic';

const Home = () => {
  const [user, setUser] = useContext(UserContext);
  const [allStories, setAllStories] = useState([]);
  const [followingStories, setFollowingStories] = useState([]);
  const [myStories, setMyStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currTopic, setCurrTopic] = useState("all");
  const [search, setSearch] = useState("");

  useLayoutEffect(() => {
    setIsLoading(true);
    Axios.get(process.env.REACT_APP_SERVER_URL + `/api/stories/topic/${currTopic}`, {}).then((res) => {
      setAllStories(res.data);
      setIsLoading(false);
      console.log(res.data);
    }).catch((err) => {
      setIsLoading(false);
      console.log(err);
    })
  }, [currTopic])

  useEffect(() => {
    const didToken = Cookies.get("token");
    if (magic.user.isLoggedIn()) {
      Axios.get(process.env.REACT_APP_SERVER_URL + "/api/stories/following", {
        headers: {
          Authorization: "Bearer " + didToken
        }
      }).then((res) => {
        console.log(res.data);
        setFollowingStories(res.data)
      }).catch((err) => {
        console.log(err)
      })

      Axios.get(process.env.REACT_APP_SERVER_URL + "/api/stories/my", {
        headers: {
          Authorization: "Bearer " + didToken
        }
      }).then((res) => {
        console.log(res.data);
        setMyStories(res.data)
      }).catch((err) => {
        console.log(err)
      })
    }
  },[])

  return (
    <motion.div className="Home" initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}>
      <div className="left-con">

        <Tabs defaultActiveKey="1" className='tabs'>
          <Tabs.TabPane tab="Explore" key="1" >
            <section className='stories-con'>
              {[...Array(5)].map((_, index) => {
                return (
                  <>
                    {isLoading && (
                      <Skeleton
                        active
                        avatar
                        paragraph={{
                          rows: 3,
                        }}
                      />
                    )}
                  </>
                );
              })}
              {allStories.length > 0? allStories.map((story) => {
                return (
                  <Card story={story} />
                )
              }): "No stories to show"}
            </section>
          </Tabs.TabPane>
          {user && user.issuer ? <>
            <Tabs.TabPane tab="Following" key="2">
              <section className='stories-con'>
                {followingStories.length ? followingStories.map((story) => {
                  return (
                    <Card story={story} />
                  )
                }) : "No stories to show"}
              </section>
            </Tabs.TabPane>
            <Tabs.TabPane tab="My Stories" key="3" >
              <section className='stories-con'>
                {myStories.length ? myStories.map((story) => {
                  return (
                    <Card story={story} myStory={true} myStories={myStories} setMyStories={setMyStories} />
                  )
                }) : "You haven't written any stories yet"}
              </section>
            </Tabs.TabPane>
          </> : null}
        </Tabs>
      </div>


      <div className="right-con">
        <div className="search-con">
          <img src={searchIcon} alt="" />
          <input type="text" name="" id="" placeholder='Search' onChange={(e)=>setSearch(e.target.value)} />
        </div>

        <div className="topics-con">
          <p className='topic-head'>Topics</p>
          <div className="topics">
            {topics.length && topics.map((topic) => {
              return (
                <div className="topic" key={topic.id} onClick={() => setCurrTopic(topic.value)} id={currTopic === topic.value ? "highlight" : ""}>
                  <p>{topic.topic}</p>
                </div>
              )
            })}
          </div>
        </div>
        { user && user.issuer ? <Follow /> : null}

      </div>
    </motion.div>
  )
}

export default Home