import React, { useEffect } from 'react'
import { Collapse } from 'antd';
import Tilt from 'react-parallax-tilt';
import './Landing.css'
import appLogo from '../../assets/icons/writr.svg'
import heroHead from '../../assets/images/hero-title.svg'
import heroGraphic from '../../assets/images/graphic.gif'
import heroGradient from '../../assets/images/hero-gradient.png'
// import featureGradient from '../../assets/images/feature-gradient.png'
import decentralized from '../../assets/icons/decentralized.png'
import freedom from '../../assets/icons/freedom.png'
import ownership from '../../assets/icons/ownership.png'
import zigzag from '../../assets/images/zigzag.svg'
import zigzag2 from '../../assets/images/zigzag2.png'
import grid from '../../assets/images/grid.svg'
import discord from '../../assets/icons/discord.svg'
import github from '../../assets/icons/github.svg'
import { faqs } from './faqs';
import Aos from "aos"
import "aos/dist/aos.css"
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'
import Cookies from 'js-cookie'
import nft from '../../assets/images/nft.svg'
import economy from '../../assets/images/economy.svg'

const Landing = () => {
    const navigate = useNavigate();
    const { Panel } = Collapse;

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, []);

    return (
        <motion.div className="Landing-con" initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}>

            <img src={zigzag2} alt="_" id='two' />

            <div className='Landing'>
                <nav className='navbar'>
                    <div>
                        <img src={appLogo} alt="Writr" onClick={() => navigate("/")} />
                    </div>
                    {Cookies.get('token') ? <button onClick={() => navigate("/app")}>Go to App</button> :
                        <div className='login-con'>
                            <button id='login-btn' onClick={() => navigate("/login")}>Login</button>
                            <button onClick={() => navigate("/register")}>Signup</button>
                        </div>}
                </nav>
                <section className='hero'>
                    <img src={heroGradient} alt='gradient' className='hero-gradient' />
                    <div className='herocon-left' data-aos="fade-right">
                        <img src={heroHead} alt="The next generation blogging site" />
                        <p>Share stories, experiences or any text art and convert them to NFTs for free.</p>
                        <button>Get Started</button>
                    </div>
                    <div className='herocon-right'>
                        <img src={heroGraphic} alt="The next generation blogging site" />
                    </div>
                </section>

                <img src={zigzag} alt="_" id='one' />

                <section className='features' >
                    {/* <img src={featureGradient} alt="_" className='feature-gradient' /> */}
                    <div className="featurehead-con">
                        <p className='featurehead'>Features</p>
                        <p className='featuresmall'>Experience writing like never before!</p>
                    </div>

                    <div className='featurecard-con' >
                            <Tilt glareEnable={true} glareMaxOpacity={0.2} scale={1} glareColor="#7e47ff" glarePosition='all' tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                <div className='feature-card' data-aos="fade-up-right" data-aos-anchor-placement="center-bottom">
                                    <img src={decentralized} alt="decentralized" />
                                    <p className='featurecard-head'>Decentralized</p>
                                    <p className='featurecard-small'>Uses IPFS, a high perfomance distributed network protocol to store your blogs.</p>
                                </div>
                            </Tilt>
                        <Tilt glareEnable={true} glareMaxOpacity={0.2} scale={1} glareColor="#7e47ff" glarePosition='all' tiltMaxAngleX={5} tiltMaxAngleY={5}>
                            <div className='feature-card' data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                                <img src={freedom} alt="freedom" />
                                <p className='featurecard-head'>Freedom</p>
                                <p className='featurecard-small'>Express your thoughts, opinions and ideas without fear of censorship or legal actions. </p>
                            </div>
                        </Tilt>
                        <Tilt glareEnable={true} glareMaxOpacity={0.2} scale={1} glareColor="#7e47ff" glarePosition='all' tiltMaxAngleX={5} tiltMaxAngleY={5}>
                            <div className='feature-card' data-aos="fade-up-left" data-aos-anchor-placement="center-bottom">
                                <img src={ownership} alt="ownership" />
                                <p className='featurecard-head'>Ownership</p>
                                <p className='featurecard-small'>You own what you write, so we ensure complete control over your blogs.</p>
                            </div>
                        </Tilt>
                    </div>

                    <img src={zigzag} alt="_" id='three' />
                </section>

                <section className='minting'>
                    <div className='minting-left'>
                        <h1>Your Writing as NFTs</h1>
                        <p> <span>Writr</span> NFTs are sequences of characters stored on the Ethereum Polygon Blockchain as NFTs.
                            You can mint any of your writings for free using our app. </p>
                        <p>For example, if you own the <span>writr</span> NFT,only you will own it.
                            No one can ever write a <span>writr</span> NFT and no one else can own it unless you decide to transfer it. </p>

                    </div>
                    <div className='minting-right' data-aos="fade-up-left">
                        <img src={nft} alt="nft" />
                    </div>
                </section>

                <section className='economy'>
                    <div className='economy-left' data-aos="fade-up-right" data-aos-anchor-placement="bottom-bottom">
                        <img src={economy} alt="economy" />
                    </div>
                    <div className='economy-right'>
                        <h1>Creators Economy</h1>
                        <p>Trade your <span>writr</span> NFTs to make profit off of your writings. We are dedicated to plagiarism, ensuring a healthy ecosystem for our writrs.</p>
                    </div>
                </section>


                <section className='faq'>
                    <div className="featurehead-con">
                        <p className='featurehead'>FAQs</p>
                        <p className='featuresmall'>Can't find the answer you need? <span> <a href="https://discord.gg/9saMayYZEG">Contact Us</a> </span> </p>
                    </div>
                    <div className="collapse-con">
                        <Collapse className='collapse'>
                            {faqs.map((faq, index) => {
                                return (
                                    <Panel header={faq.question} key={index} className="panel">
                                        <p>{faq.answer}</p>
                                    </Panel>
                                )
                            })}
                        </Collapse>
                    </div>

                    <img src={grid} alt="" className='grid' />

                </section>
                <footer className='footer'>
                    <div className="brand">
                        <img src={appLogo} alt="Writr" />
                    </div>
                    <p className="copyright">©Writr 2022</p>
                    <div className="socials">
                        <a href="https://github.com/anomic30/Writr">
                            <img src={github} alt="GitHub" />
                        </a>
                        <a href="https://discord.gg/9saMayYZEG">
                            <img src={discord} alt="Discord" />
                        </a>
                    </div>
                </footer>
            </div>
        </motion.div>
    )
}

export default Landing