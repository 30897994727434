import React from 'react'
import './Card.css'
import { Avatar } from 'antd';
import heart from '../../assets/icons/heart.svg'
import bin from '../../assets/icons/bin.svg'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';

const Card = ({ story, myStory, myStories, setMyStories }) => {
    const navigate = useNavigate();

    const handleDelete = (id) => {    
        const didToken = Cookies.get("token");
        Axios.delete(process.env.REACT_APP_SERVER_URL + "/api/stories/delete/" + id, {
          headers: {
            Authorization: "Bearer " + didToken
          }
        }).then((res) => {
          toast.success('Story deleted successfully',
          {iconTheme: {
            primary: '#7E47FF',
            secondary: '#fff',
          }});
          console.log(res.data);
          setMyStories(myStories.filter((story) => story._id !== id));
        }).catch((err) => {
          console.log(err);
        })
    }
    
    return (
        <div className='Card'>
            <section className="user-info">
                {myStory ? <div className='bin' >
                    <Tippy content='Delete' placement='right-start'>
                        <button id='bin' onClick={()=>handleDelete(story?._id)}>
                            <img src={bin} alt="Delete" arrow="" />
                        </button>
                    </Tippy>
                </div> :
                    <>
                        <Avatar size={40} style={{ color: '#7E47FF', backgroundColor: '#DCCDFF', cursor: 'pointer' }}>{story?.userName?.slice(0, 1)}</Avatar>
                        <p>{story?.userName}</p>
                    </>
                }
            </section>
            <section className="card-title" onClick={()=>navigate("/app/story/"+story._id)}>
                <p>{story?.title}</p>
            </section>
            <section className="card-brief">
                <p>{story?.brief}</p>
            </section>
            <section className="card-stats">
                <div className='likes'>
                    <img src={heart} alt="Heart" />
                    <p>{story?.likes?.length}</p>
                </div>
                <p>
                    {moment(story?.createdAt).format('Do MMM YY')}
                </p>
            </section>
        </div>
    )
}

export default Card