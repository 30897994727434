import './App.css';
import { BrowserRouter as Router} from 'react-router-dom';
import { UserContext } from './utils/userContext';
import { ProfileContext } from './utils/profileContext';
import { magic } from './utils/magic';
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react';
import AnimatedRoutes from '../src/components/AnimatedRoutes'
import { Toaster } from 'react-hot-toast';

function App() {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState();

  useEffect(() => {
    console.log("App.js useEffect");
    setUser({ loading: true });
    const init = async () => {
      try {
        const isLoggedIn = await magic.user.isLoggedIn();
        if (isLoggedIn) {
          const userData = await magic.user.getMetadata();
          setUser(userData);
          const idToken = await magic.user.getIdToken({ lifespan: 24 * 60 * 60 });
          Cookies.set('token', idToken);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setUser((prevState) => ({ ...prevState, loading: false }));
      }
    };
    init();
  }, []);

  return (
    <div className="App">
      <Toaster />
      <UserContext.Provider value={[user, setUser]}>
        <ProfileContext.Provider value={[profile, setProfile]}>
          <Router>
            <AnimatedRoutes/>
          </Router>
        </ProfileContext.Provider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
