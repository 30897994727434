import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import './Follow.css'
import { Avatar, Skeleton } from 'antd';
import Axios from 'axios';
import { ProfileContext } from '../../utils/profileContext';
import Cookies from 'js-cookie'
import toast from 'react-hot-toast';
import { UserContext } from '../../utils/userContext';

const Follow = () => {
    const [usersToFollow, setUsersToFollow] = useState([]);
    const [profile] = useContext(ProfileContext);
    const [user] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const didToken = Cookies.get("token");
        Axios.get(process.env.REACT_APP_SERVER_URL + "/api/user/random", {
            headers: {
                Authorization: "Bearer " + didToken
            }
        }).then((res) => {
            setUsersToFollow(res.data.users);
            setIsLoading(false);
            console.log(res.data.users);
        }).catch((err) => {
            setIsLoading(false)
            console.log(err.response.data)
        });
    }, [user.issuer]);

    const followUser = (id) => {
        const didToken = Cookies.get("token");
        Axios.post(process.env.REACT_APP_SERVER_URL + "/api/user/follow", {
            id: id
        }, {
            headers: {
                Authorization: "Bearer " + didToken
            }
        }).then((res) => {
            console.log(res.data);
            toast.success("Followed User");
        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data);
        });
    }

    return (
        <div className='Follow'>
            <p>Writrs to follow</p>
            {isLoading && [...Array(2)].map((_, index) => {
                return (
                    <Skeleton
                        active
                        avatar
                        paragraph={{
                            rows: 0,
                        }}
                    />
                );
            })}
            {usersToFollow.length && usersToFollow.map((user) => {
                return (
                    <div className='follow-user' key={user.id}>
                        <div className='side'>
                            <Avatar size={40} style={{ color: '#7E47FF', backgroundColor: '#DCCDFF', cursor: 'pointer' }}>{user?.userName.slice(0, 1)}</Avatar>
                            <p>{user?.userName}</p>
                        </div>
                        <button onClick={() => followUser(user?._id)}>
                            Follow
                        </button>
                    </div>
                )
            })}
        </div>
    )
}

export default Follow